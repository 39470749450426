<template>
  <div id="admin-add">
    <v-container>
      <div class="operate d-flex justify-end">
        <div class="back">
          <v-btn
            dark
            color="primary"
            class="add align-self-center"
            @click="$router.go(-1)"
          >
            <v-icon dark> mdi-backburger </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="content">
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="data.account"
            :rules="accountRules"
            label="请填写账号"
            required
            :error-messages="errorMessages.account"
          ></v-text-field>

          <v-text-field
            v-model="data.password"
            :rules="passwordRules"
            label="请填写密码"
            type="password"
            :required="!id"
          ></v-text-field>
          <v-text-field v-model="data.name" label="请填写姓名"></v-text-field>
          <v-list dense class="pl-0 pt-0">
            <v-subheader class="pl-0">权限</v-subheader>
            <v-list-item-group v-model="data.permissionSetting" multiple>
              <v-list-item
                v-for="(permissionItem, index) in permissionItems"
                :key="index"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="permissionItem.title"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div class="d-flex justify-end">
            <v-btn class="px-16" color="success" x-large @click="save">
              保存
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["menus"]),
    permissionItems() {
      return this.menus.slice(1);
    },
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;
      (!this.id
        ? this.$shareyueApi.addAdmin(this.data)
        : this.$shareyueApi.editAdmin(this.id, this.data)
      )
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          if (!error) return;
          for (var key in error) {
            if (key in this.errorMessages) {
              this.errorMessages[key] = error[key];
            }
          }
        });
    },
    async load() {
      this.id = this.$route.query.id;
      if (!this.id) {
        for (var i = 0; i < this.menus.length - 1; i++) {
          this.data.permissionSetting.push(i);
        }
        return;
      }

      await this.$shareyueApi.getAdmin(this.id).then((data) => {
        Object.assign(
          this.data,
          (({ account, name, permission }) => ({
            account,
            name,
            permission,
          }))(data)
        );
        if (this.data.permission) {
          this.data.permission.split(",").forEach((key) => {
            let index = this.permissionItems.findIndex((p) => p.key == key);
            if (index != -1) {
              this.data.permissionSetting.push(index);
            }
          });
        }
      });
    },
  },
  watch: {
    "data.permissionSetting"() {
      let permission = [];
      this.data.permissionSetting.forEach((k) => {
        permission.push(this.permissionItems[k].key);
      });
      this.data.permission = permission.join();

      //console.error(this.data.permission);
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      id: "",
      data: {
        account: "",
        password: "",
        name: "",
        permission: "",
        permissionSetting: [],
      },
      valid: true,
      errorMessages: {
        account: "",
      },
      accountRules: [(v) => !!v || "请填写账号"],
      passwordRules: [(v) => !!v || !!this.id || "请填写密码"],
    };
  },
};
</script>
<style lang="less" scoped>
</style>
